@import "./ExportDropdown/style";

.outer-table-container {
  background-color: #fff;
  padding: 15px 0 0 0;

  .ant-input-search {
    .anticon {
      color: $white;

      &.anticon-close-circle {
        color: $grey;
      }
    }
  }

  .ant-table-pagination {
    padding-right: 15px;

    > * {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    a {
      font-size: 14px;
      font-family: sans-serif;
    }
  }

  .ant-table-body {
    @include scrollbars(8px, rgba($grey, 0.7), rgba($grey, 0.1));
  }

  .ant-table-thead > tr > th {
    background: $white;
    white-space: nowrap;
  }

  .ant-pagination-item {
    border-radius: 10px;

    &:hover {
      border-color: $primary;

      a {
        color: $primary;
      }
    }
  }

  .ant-pagination-item-active {
    border-color: $primary;

    a {
      color: $primary;
    }
  }

  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    border-radius: 10px;
  }

  .ant-pagination-prev:hover .ant-pagination-item-link:not([disabled]),
  .ant-pagination-next:hover .ant-pagination-item-link:not([disabled]) {
    border-color: $primary;
    color: $primary;
  }

  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: $primary;
  }

  .ant-table-column-sorter-up.active,
  .ant-table-column-sorter-down.active,
  .ant-table-filter-trigger.active {
    color: $white;
    background-color: $primary;
  }

  .ant-table-column-sorter-inner {
    height: 20px;
    justify-content: space-between;
  }

  .ant-table-filter-trigger {
    border-radius: 5px;
    margin: -1px -6px -4px 4px;
    height: 25px;
  }

  td.ant-table-column-sort {
    background-color: $white;
  }

  &.loading {

    .table-left-actions {
      opacity: .5;
      position: relative;

      &:after {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        z-index: 100;
      }
    }
  }
}

.ant-table-filter-dropdown {
  .ant-picker-range {
    display: flex !important;
  }
}

.table-actions-column {
  display: flex;
  align-items: center;

  a {
    margin-right: 10px;
    line-height: 1;

    &:last-child {
      margin-right: 0;
    }
  }
}

.ant-table-fixed {
  table-layout: fixed;
}

.ant-table-thead > tr > th {
  color: $textColor;
  border-color: $border-table;
}

.ant-table-tbody > tr > td {
  word-wrap: break-word;
  font-weight: 300;
  border-color: $border-table;

  &:first-child {
    border-left: 2px solid transparent;
    transition: $transition;
  }

  &.ant-table-cell-row-hover {
    &:first-child {
      border-left: 2px solid $secondary;
    }
  }
}

.ant-table-tbody > tr.ant-table-row-selected {
  &:hover > td {
    background-color: lighten($secondary, 35%);
  }

  > td {
    background-color: lighten($secondary, 35%);
  }
}

.ant-spin-dot-item {
  background-color: $primary;
}

.table-filter-dropdown {
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: 250px;

  .ant-space {
    margin-top: 10px;
  }
}

.table-outer-filters {
  padding: 0 15px;

  &.p-0 {
    padding: 0;
  }

  .search-filter {
    margin-right: 10px;
    margin-bottom: 10px;
    width: 200px;
  }

  .table-right-actions {
    margin-left: auto;
    display: inline-flex;
    flex-wrap: wrap;

    .action-btn {
      margin: 0 15px 10px 0;
      min-width: 140px;

      &:last-child {
        margin-right: 0;
      }
    }

    @media (max-width: 1350px) {
      display: contents;
    }
  }

  .table-left-actions {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex: 1;

    .table-external-filters {
      margin-right: 10px;
      margin-bottom: 10px;
      flex: 1;

      .ant-select {
        min-width: 200px;
        max-width: 250px;
      }
    }
  }
  .table-clear-filters-actions {
    margin-right: 15px;
  }
}

.ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
  .ant-table-cell .ant-table-expanded-row-fixed {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100px;
    width: 100% !important;
  }
}

.filter-range-date-picker {
  min-width: 300px;
  .ant-picker-input > input {
    font-size: 14px;
  }
}
